import React from 'react'

import IconsSwitch from 'src/components/IconsSwitch/_index'
import * as S from './_styles'
import { orange } from 'src/styles/colors'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const ShowMore = ({ children }: React.PropsWithChildren) => {
  const [ showMore, setShowMore ] = React.useState<boolean>(false)

  const [ sendDataLayer ] = useDataLayer()

  const ButtonShowMore = showMore
  ? (
    <S.ShowMoreButton
      onClick={() => {
        sendDataLayer({
          section: 'dobra_01',
          section_name: 'Olá, bem-vindo(a) à websérie Tudo sobre Cripto!',
          element_name: 'Mostrar menos',
          element_action: 'click button',
        })
        setShowMore(!showMore)
      }}
    >
      Mostrar menos
      <IconsSwitch
        icon='ic_chevron_up'
        lib='interco'
        width='20'
        height='20'
        color={orange[500]}
        customPath='action-navigation/'
        className='icon_source'
      />
    </S.ShowMoreButton>
  )
  : (
    <S.ShowMoreButton
      onClick={() => {
        sendDataLayer({
          section: 'dobra_01',
          section_name: 'Olá, bem-vindo(a) à websérie Tudo sobre Cripto!',
          element_name: 'Mostrar mais',
          element_action: 'click button',
        })
        setShowMore(!showMore)
      }}
    >
      Mostrar mais
      <IconsSwitch
        icon='ic_chevron_down'
        lib='interco'
        width='20'
        height='20'
        color={orange[500]}
        customPath='action-navigation/'
        className='icon_source'
      />
    </S.ShowMoreButton>
  )

  return (
    <div>
      <S.ShowMoreWrapper
        opened={showMore}
      >
        {children}
      </S.ShowMoreWrapper>
      {ButtonShowMore}
    </div>
  )
}

export default ShowMore
